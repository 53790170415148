// Index
//1. Font size variants
// text properties

a {
  text-decoration: none;
}



h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

//Font sizes

h1{
  font-size: 48px;
}

h2{
  font-size: 32px;
}

h3{
  font-size: 20px;
}

h4{
  font-size: 18px;
}

h5{
  font-size: 14px;
}

h6{
  font-size: 12px;
}

.header-logo-text{
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
}

.header-logo-subheading{
  font-size: 7px;
  font-weight: 600;
  text-transform: uppercase;
}

.sub-heading{
  font-size: 24px;
}

.footer-text{
  font-size: 14px;
}

.subscription-toggle-btn{
  font-size: 14px;
}

.order-summary-heading{
  font-size: 30px;
  color: $white;
  font-weight: 700;
}


p,span,a{
  font-size: 16px;
}
