// Index
// 1. Flexbox
// 2. Width Height
// 3. Text styles
// 4. Box Shadow
// 5. Scrollbar
// 6. Global chart
// 7. Loops
// 8. Snackbar
// 9. Cursor
// 10. bg colors  
// 11. Position

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.bg-transparent {
  background-color: transparent;
}

.border-none {
  border: none !important;
}

// Flexbox
.d-flex {
  display: flex;
}

.d-inline-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline-block {
  display: inline-block;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;

}

.fw-700 {
  font-weight: 700 !important;
}


.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;

}

// Width Height
.w-100 {
  width: 100%;
}

// v
.w-50 {
  width: 50%;
}

.h-auto {
  height: auto;
}

.h-100vh {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

// Text styles
.text-nowrap {
  white-space: nowrap;
}

.text-wrap {
  white-space: pre-wrap;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.word-break {
  word-break: break-word;
}

// Scrollbar

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b6adad;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F3F3F3;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.grid-px-8 {
  >div {
    padding-right: .5rem;
    padding-left: .5rem;
  }
}

//Loops
$spacing: 4px;

// SPACING// Margin Loop
@for $i from 0 through 12 {
  .mt-#{$i} {
    margin-top: $i * $spacing !important;
  }

  .mr-#{$i} {
    margin-right: $i * $spacing !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * $spacing !important;
  }

  .ml-#{$i} {
    margin-left: $i * $spacing !important;
  }

  .my-#{$i} {
    margin-top: $i * $spacing !important;
    margin-bottom: $i * $spacing !important;
  }

  .mx-#{$i} {
    margin-right: $i * $spacing !important;
    margin-left: $i * $spacing !important;
  }

  .ml-n-#{$i} {
    margin-left: -$i * $spacing !important;
  }

  .m-#{$i} {
    margin: $i * $spacing !important;
  }
}

// Padding Loop
@for $i from 0 through 12 {
  .p-#{$i} {
    padding: $i * $spacing !important;
  }

  .pt-#{$i} {
    padding-top: $i * $spacing !important;
  }

  .pr-#{$i} {
    padding-right: $i * $spacing !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * $spacing !important;
  }

  .pl-#{$i} {
    padding-left: $i * $spacing !important;
  }

  .py-#{$i} {
    padding-top: $i * $spacing !important;
    padding-bottom: $i * $spacing !important;
  }

  .px-#{$i} {
    padding-right: $i * $spacing !important;
    padding-left: $i * $spacing !important;
  }
}

// border-radius/

@for $i from 0 through 12 {
  .br-#{$i} {
    border-radius: $i * $spacing !important;
  }
}

$gap: 1px;

@for $i from 0 through 100 {
  .gap-#{$i} {
    gap: $i * $gap !important;
  }
}

//line height loop
@for $i from 0 through 12 {
  .lh-#{$i} {
    line-height: $i * $spacing !important;
  }
}

//Border Radius

@for $i from 0 through 12 {
  .border-radius-#{$i} {
    border-radius: $i * $spacing !important;
  }
}

// 3. Position
.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.inherit {
  position: inherit;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-50 {
  left: 50%;

}

.opacity-0 {
  opacity: 0;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

// Cursor
.pointer {
  cursor: pointer;
}


// bg colors  


// Other CSS----------------------------------------
.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

//disable docs controls
.docblock-argstable {
  display: none !important;
}

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

// v
.z-1 {
  z-index: 1;
}


.text-end {
  text-align: end;
}

.mt-auto {
  margin-top: auto;
}


img {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.profile-email-ellipse {
  width: 168px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.object-fit-cover {
  object-fit: cover !important;
}

.validation-error {
  font-size: 14px;
  display: block;
  margin-top: 5px;
  color: #EA3829 !important;
  font-family: "Montserrat", sans-serif !important;
}

.word-break-all {
  word-break: break-all !important;
}


.capchta-highlighter {
  border: 1px solid black;
  border-radius: 100%;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  background: #14413e;
  cursor: pointer;

  svg {
    path {
      fill: white;
    }
  }
}

.mat-mdc-form-field {
  input {
    border: none !important;
    padding: 0px !important;
    height: auto;
  }

  &.mat-mdc-form-field-type-mat-select {
    &.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
      color: #14413e;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      padding: 12px 0;
      height: 48px;
      min-height: 48px;

      span {
        font-size: 14px;
      }

      .mat-mdc-select-placeholder {
        color: #ACB8C2;
      }
    }
  }

  .mat-datepicker-toggle-active {
    color: #14413E;
  }
}

.form-field {
  display: flex;
  flex-wrap: wrap;

  mat-label {
    color: #3A3742;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;

    @media only screen and (max-width: 1024px) {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
    position: relative;

    .mat-mdc-text-field-wrapper {
      border: 1px solid #E5E7EB;
      border-radius: 8px;
      background: white;

      input {
        font-size: 14px;
        font-family: "Nunito Sans";
        color: black;
        height: auto !important;

        &::placeholder {
          color: #AAAAAA;
          font-family: "Nunito Sans";
        }
      }

      &.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding-top: 12px;
        padding-bottom: 12px;
        min-height: unset;
        display: flex;
      }

      .mat-datepicker-toggle {
        height: 26px;

        .mat-mdc-icon-button {
          padding: 0;
          height: 26px;
          width: 26px;

          .mat-mdc-button-persistent-ripple {
            display: none;
          }

          .mat-mdc-button-persistent-ripple {
            display: none;
          }
        }
      }
    }

    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }

    .mdc-line-ripple {

      &::before,
      &::after {
        display: none;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      position: absolute;
      top: 100%;
      line-height: 15px;

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
    }

    .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
      color: var(--mdc-filled-text-field-disabled-input-text-color);
    }
  }
}

.custom-form-field {
  position: relative;

  .validation-error {
    position: absolute;
    top: calc(100% + 2px);
    margin: 0 !important;
    font-size: 12px;
  }
}

.mat-mdc-checkbox {
  font-family: "Nunito Sans";
  font-weight: 500;

  .mdc-label {
    margin-top: 3px;
    color: #3A3742;
    font-size: 16px;
    font-family: "Nunito Sans";
  }

  .mdc-checkbox {
    --mdc-checkbox-selected-focus-icon-color: #14413E !important;

    &:hover {

      .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
      .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
      .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
        border-color: #14413E;
        background-color: #14413E;
      }
    }

    .mdc-checkbox__background {
      width: 20px;
      height: 20px;
    }

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
      border-color: #AAAAAA;
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:focus:indeterminate~.mdc-checkbox__background {
      border-color: #14413E;
      background-color: #14413E;
    }

    .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
      background-color: #14413E;
    }

    &:not(:disabled):active .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    &:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    &:not(:disabled):active .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
      border-color: #14413E;
      background-color: #14413E;
    }
  }
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    font-size: 14px;

    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: #14413E;
    }
  }

  &.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after,
  &.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
    color: #14413E;
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      min-height: 42px;

      .mat-mdc-menu-item-text {
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
  }
}

.user-profile-menu.mat-mdc-menu-panel {
  min-width: 300px;
  padding: 24px;
  border-radius: 0;
  box-shadow: 0px 14px 18px #0000560F;

  @media screen and (max-width: 1024px) {
    padding: 18px;
  }

  .mat-mdc-menu-content {
    padding: 0;

    .user-profile {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 18px;
      }

      .profile {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 15px;

        @media screen and (max-width: 1024px) {
          width: 50px;
          height: 50px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .user-details {
        display: inline-flex;
        width: calc(100% - 75px);
        flex-direction: column;
        justify-content: center;

        h3 {
          color: #333333;
          font-size: 24px;
          margin: 0 0 2px;
          font-weight: 500;
          font-family: "Nunito Sans";

          @media screen and (max-width: 1024px) {
            font-size: 20px;
            margin: 0;
          }
        }

        span {
          color: #3A3742;
          font-size: 14px;
          font-family: "Nunito Sans";
        }
      }
    }

    .mat-mdc-menu-item {
      padding: 0 20px;
      border-bottom: 1px solid #C4C3C7;

      @media screen and (max-width: 1024px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        min-height: 42px;
      }

      &:last-child {
        border: none;
      }

      &:not([disabled]):hover {
        background-color: #fbfbfb;
      }

      img {
        max-width: 17px;
        margin-right: 20px;

        @media screen and (max-width: 1024px) {
          margin-right: 16px;
        }

      }

      span {
        color: #3A3742;
        font-size: 16px;
        font-family: "Nunito Sans";
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .25);
  filter: blur(14px);
}

.listing-wrapper {
  height: calc(100vh - 69px);
  background-color: #F1F6F6;
  display: flex;
  flex-direction: column;

  h3 {
    margin: 29px 35px 27px;
    font-size: 24px;
    color: #000000;
    font-family: "Nunito Sans";
    font-weight: 600;

    @media screen and (max-width: 1024px) {
      margin: 28px 20px 15px;
      font-size: 22px;
    }
  }

  .listing-inner-wrapper {
    height: calc(100vh - 153px);
    background: white;
    overflow: auto;
    position: relative;

    @media screen and (max-width: 1024px) {
      height: calc(100vh - 140px)
    }
  }
}

.text-upper {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.mat-mdc-radio-button {
  margin-right: 26px;

  .mdc-label {
    color: #14413E;
    font-size: 14px;
  }

  &.mat-mdc-radio-checked {
    .mdc-radio__background {
      &::before {
        background-color: #14413E !important;
      }
    }

    .mat-ripple-element {
      background-color: #14413E !important;
    }
  }

  .mdc-radio {
    padding-left: 0 !important;

    .mdc-radio__native-control:enabled {
      &:checked {
        &+.mdc-radio__background {
          .mdc-radio__outer-circle {
            border-color: #14413E !important;
          }
        }
      }

      &:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
        border-color: #AAAAAA;
      }
    }

    .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
      border-color: #14413E !important;
    }

    input {
      border-color: #AAAAAA !important;
    }
  }
}

.country-dropdown-menu {
  max-height: 206px;
}

.active-badge {
  height: 29px;
  padding: 0 13px;
  background: #3EB049;
  color: white;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';

  .material-icons {
    font-size: 14px;
    margin-right: 8px;
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch {

    &:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
    &:enabled .mdc-switch__track::after,
    &:enabled:focus:not(:active) .mdc-switch__track::after,
    &:enabled:active .mdc-switch__track::after {
      background: #14413E;
    }

    &.mdc-switch--selected:enabled .mdc-switch__handle::after {
      background: white;
    }

    .mdc-switch__track {
      height: 16px;
    }

    .mdc-switch__handle {
      width: 16px;
      height: 16px;

      .mdc-switch__icons {
        background: white;
        border-radius: 50%;

        .mdc-switch__icon {
          width: 16px !important;
          height: 16px !important;
          display: none;
        }
      }
    }

    .mdc-switch__handle-track {
      width: calc(100% - 16px);
    }
  }
}

.mat-mdc-table.mdc-data-table__table {
  thead {
    background: #F9FBFF;

    .mat-mdc-header-row {
      height: 50px;
    }

    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        color: black;
        font-size: 14px;
        font-family: 'Nunito Sans';
        font-weight: 600;
        border-color: #F2F2F2;
      }
    }

    .mdc-data-table__content {
      .mat-mdc-cell {
        color: #3A3742;
        font-size: 14px;
        font-family: 'Nunito Sans';
        border-color: #F2F2F2;
      }
    }
  }
}

.mat-mdc-paginator {
  .mat-mdc-paginator-container {
    justify-content: center;
    padding: 16px 8px;

    .mat-mdc-form-field {
      height: 38px !important;
      border-bottom: 1px solid #3a3742;
      width: 64px;

      .mat-mdc-text-field-wrapper {
        --mdc-outlined-text-field-outline-width: 0;
        padding: 0 10px;
      }
    }
  }
}

.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, .25);
  padding: 30px 20px;
  width: 100%;
  min-width: 100%;
  display: none;

  &.show {
    display: block;
  }
}

.cdk-overlay-pane {
  max-width: 95% !important;
  justify-content: center;

  .mdc-dialog__container {
    min-width: 100% !important;
  }
}

.table-wrapper {
  overflow: auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .date-time {
    min-width: 170px;
  }

  .tax-type {
    min-width: 220px;
  }

  .plan {
    min-width: 110px;
  }

  .amount {
    min-width: 130px;
  }

  .billing {
    min-width: 200px;
  }

  .status {
    min-width: 110px;
  }

  .action {
    min-width: 110px;

    .material-symbols-outlined {
      font-size: 20px;
      cursor: pointer;
      margin-top: 6px;
    }
  }
}

.super-admin-container {
  img {
    height: auto;
    width: auto;
    min-height: unset;
  }
}

// LOADER
.CenterGlobalLoader {
  background: rgba(0, 0, 0, 0.474);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  vertical-align: middle;
  z-index: 9;

  .GlobalPageLoader {
    top: 45%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    margin: 0 auto;
    position: absolute;

    div {
      position: absolute;
      border: 2px solid $white;
      border-radius: 100%;
      opacity: 1;
      @include border-radius(50%);
      animation: GlobalPageLoader 1.3s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}

@keyframes GlobalPageLoader {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}


.filter-drawer {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 2;
  box-shadow: 0px 0px 54px 0 rgb(0 0 0 / 10%);
  overflow: auto;
  right: -450px;
  transition: 0.3s all ease;
  max-width: 450px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    right: -110%;
  }

  &.show {
    right: 0;
  }

  .drawer-header {
    padding: 22px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F6F6FC;

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    h2 {
      margin: 0;
      font-size: 22px;
      font-family: "Nunito Sans";
      font-weight: 600;
    }

    span {
      color: #14413E;
      cursor: pointer;
    }
  }
}

.filter-drawer-overlay {
  background: rgb(0 0 0 / 25%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: none;

  &.show {
    display: block;
  }
}

.mat-datepicker-content {
  .mat-calendar {
    .mat-calendar-body-selected {
      background: #14413E;
    }
    .mat-calendar-body-in-range {
      &::before {
        background: #14413e42;
      }
      &>.mat-calendar-body-comparison-identical, .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
        background: #185854;
      }
    }
  }
}