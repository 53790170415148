.container {
    max-width: 1600px;
    margin: auto
}



/// buttons
.btn {
    height: 36px;
    padding: 0;

    &.primary-btn {
        background-color: #14413E;
        color: white;
        border: none;

        &:hover {
            background-color: #14413E;

        }
    }

    &.outlined-btn {
        background-color: transparent;
        border: 1px solid #14413E;

        &:hover {
            background-color: transparent;
            border: 1px solid #14413E;
        }
    }

    &.stretch-btn {
        height: 40px;
        width: 100%;
        padding: 0 10px;
    }

    &.availablity-btn {
        text-transform: uppercase;
        background-color: #14413E59;
        color: #14413E;
        height: 40px;
        font-size: 14px;
        min-width: 178px;
    }

    &.primary-background-color {
        &.btn:first-child:active {
            background-color: #14413e;
            color: #fff;
        }

        &:hover {
            background-color: #14413e;
        }
    }
}

input {
    border: 1px solid #E5E7EB !important;
    padding: 0px 16px 0px 16px !important;
    height: 48px;

    &:focus {
        box-shadow: none !important;
    }

    &::placeholder {
        color: #AAAAAA !important;
        font-family: "Montserrat", sans-serif;
    }

    &.form-control:disabled{
        background-color: #f3f3f3;
        cursor: not-allowed;
    }
}

.custom-tooltip {
    .tooltip-inner {
        max-width: fit-content !important;
    }
}


#custom-checkbox[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 13px;
    height: 13px;
    min-width: 13px;
    max-width: 13px;
    border: 1px solid #545454 !important;
    border-radius: 3px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  
  #custom-checkbox:checked::before {
    content: '\2714'; 
    display: inline-block;
    font-size: 9px;
    color: #ffffff;
  }
  
  #custom-checkbox:checked {
    background-color: #007bff;
    border: 1px solid #007bff !important; 
  }

  .custom-country-code-dropdown{
    .country-code-dropdown-text{
        border: 1px solid #E5E7EB !important;
        padding: 0 16px !important;
        height: 48px;
        width: 100%;
        border-radius: 4px;
    }
  }