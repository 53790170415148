/*-------------------------------------------------------------------------------------- HOMEPAGE START ---------------------------------------------------------------------------*/

// Banner-section-start
.banner-carousel {
  height: 100vh;
  position: relative;

  .header {
    position: absolute;
    top: 34px;
    right: 68px;
    margin: auto;
    width: calc(100% - 136px);
    z-index: 1;
  }

  .carousel {
    height: 100%;

    .carousel-indicators {
      bottom: 86px;

      .indicator-btn {
        height: 10px;
        width: 40px;
        border-radius: 9px;
        border: 1px solid transparent;
      }
    }

    .carousel-inner {
      height: 100%;

      .carousel-item {
        height: 100%;
      }
    }

    .carousel-btn {
      z-index: 0;

      .carousel-control-next-icon {
        height: 43px;
        width: 43px;
        border-radius: 100%;
        background-color: #FFFFFF99;
        padding: 10px;
        color: black;
        background-image: none;

      }
    }
  }

  .banner-text-wrapper {
    display: inline-block;
    bottom: 67px;
    left: 69px;
    background-color: rgba(255, 255, 255, 0.4);
    max-width: 512px;
    backdrop-filter: blur(40px);

    .baner-text {
      text-transform: uppercase;
    }
  }
}

.listing-svg {
  min-width: 14px;
  max-height: 14px;
}

// Banner-section-end


// Top Button-start
.top-btn-wrapper {
  position: fixed;
  right: 40px;
  bottom: 40px;
  height: 50px;
  width: 50px;
  background-color: #70948f;
  border-radius: 100%;
  overflow: hidden;
  z-index: 99;

  .top-btn {
    background: none;
    border: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .top-icon {
      color: $white;
      font-size: 16px;
    }
  }

  &:hover {
    background-color: #123331;
  }
}

//Top button end

.start-btn-mobile {
  display: none;
}

// Brand-section-start
.brands-wrapper {
  max-width: 1045px;
  margin: auto;
  background: $white;

  .brand-heading {
    margin-bottom: 30px;
  }

  .brand-images-wrapper {
    gap: 68px;

    .brand-image {
      width: 140px;
      height: 43px;

      img {
        object-fit: contain;
      }
    }
  }
}

// Brand-section-end


// Software-section-start
.software-services-wrapper {
  padding: 64px 0;
  background: #F8F4EE;

  .section-design {
    width: 264px;
    max-height: 44px;
  }



}

// Software-section-end


// Services-section-start
.our-services-wrapper {
  padding: 64px 0;
  background: #F8F4EE;

  .services-card-wrapper {
    margin-top: 84px;

    .doodle-art-services {
      bottom: 39px;
    }

    .service-card {
      padding-top: 63px;
      padding-bottom: 37px;

      // .new-flag {
      //   position: absolute;
      //   right: 2px;
      //   top: 26px;
      //   border-top-left-radius: 6px;
      //   border-bottom-left-radius: 6px;
      //   overflow: hidden;
      //   text-transform: capitalize;
      //   font-size: 12px;
      //   font-weight: 600;
      //   border: none;
      //   padding: 6px;
      //   background-color: #159C49;
      //   color: $white;
      // }

      .card-icon-wrapper {
        top: -25%;
        left: 50%;
        transform: translateX(-50%);
        height: 85px;
        width: 85px;
      }

      .service-card-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .service-card-text {
          margin: 17px 0 35px;
        }

        .software-btn-wrapper {
          text-align: center;
          margin-top: auto;

          .card-btn {
            max-width: 389px;
          }
        }
      }

      &:hover {
        box-shadow: 0 0 30px #0000000d;
      }
    }
  }

}

// Services-section-end


// Gallery-section-start
.gallery-wrapper {
  padding: 64px 0;
  background: url(../../images/product-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #F8F4EE;

  .product-image-wrapper {
    .product-image {
      max-height: 482px;
      max-width: 482px;
    }

    .product-text-wrapper {
      width: fit-content;
      margin: auto;
      text-align: center;
      padding: 6px 10px;
      border-radius: 6px;
      background: #14413E40;

      .product-text {
        font-size: 20px;
        color: #14413E;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    &:nth-child(2) {
      margin-top: 262px;
    }
  }

  .product-man-wrapper {
    width: 230px;
    height: 209px;
    bottom: 0;
    right: 269px;
  }
}

// Gallery-section-end


// Training-section-start
.butlermax-training-wrapper {
  padding: 64px 0;
  background: url(../../images/services-section-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #F8F4EE;

  .offer-helping-text {
    margin: 64px 0;
    text-transform: uppercase;
    letter-spacing: 12px;
  }
}

// Training-section-end


// Training-section-start
.it-consulting {
  background: #F8F4EE;
  overflow: hidden;
  max-width: 100%;

  .left-design {
    height: 56px;
    top: 140px;
    left: -340px;
  }

  .right-design {
    height: 624px;
    right: -348px;
    top: -117px;
    transform: rotate(360deg);
  }
}

// Training-section-end

// Trust-Us-Section-start 
.people-trustus-wrapper {
  height: 1013px;
  overflow: hidden;

  .people-trust-wrapper {
    max-width: 940px;
    width: 100%;
    margin: 64px auto 0;
    height: 400px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");

    .people-trust {
      width: 1042px;
      top: -38px;
      left: -50px;

      .client-testimonial {
        .client-testimonial-image {
          height: 84px;

          img {
            object-fit: contain;
          }
        }

        .testiminial-text {
          font-size: 18px;
        }

        &:nth-child(1) {
          width: 124px;
        }

        &:nth-child(2) {
          width: 133px;
          margin-left: 30px;
        }

        &:nth-child(3) {
          width: 140px;
          margin-left: 60px;
        }

        &:nth-child(4) {
          width: 215px;
        }

        &:nth-child(5) {
          width: 133px;
        }

      }
    }

    .trust-us-design {
      height: 358px;
      width: 300px;
      left: 50%;
      top: 180px;
      transform: translate(-50%);
      border-top-right-radius: 100%;
      border-top-left-radius: 100%;
      border: 1px solid transparent;


      .client-image-top {
        width: 110px;
        height: 110px;
        border-radius: 100%;
        border: none;
        overflow: hidden;
        top: 0;
        right: 0;
      }

      .room-page-image {
        width: 297px;
        height: 167px;
        top: 134px;
        left: -94px;
      }

      .client-image-right {
        width: 61px;
        height: 96px;
        right: -30px;
        border-radius: 24px;
        top: 220px;
      }

      .room-client-image {
        width: 59px;
        height: 84px;
        top: 175px;
        left: -118px;

      }

    }

    .room-client-text {
      position: relative;
      width: 100%;
      text-align: center;
      font-weight: 500;
      top: 600px;
    }

  }

  .trust-us-right-design {
    right: -238px;
    top: 290px;
  }

  .trust-us-left-design {
    left: -200px;
    top: 130px;
  }
}

// Trust-Us-Section-end 


// Testimonial-section-start
.testimonial-wrapper {
  background: #f2ebe0c4;
  padding: 64px 0;

  .testimonial-card-wrapper {
    margin-top: 116px;

    .testimonial-card {
      padding: 71px 31px 24px 31px;
      background-color: $white;
      height: 100%;

      .testimonial-image {
        top: -50px;
        transform: translateX(-50px);
        left: 50%;
        height: 103px;
        width: 103px;
      }

      .client-details-wrapper {
        .quotation-icon-wrapper {
          background-color: #14413E1A;

          .quotation-icon {
            font-size: 24px;
          }
        }
      }

      &:hover {
        box-shadow: 0px 0px 30px 0px #0000000D;
      }
    }
  }
}

// Testimonial-section-end


// Investment-section-start
.investment-form-wrapper {
  padding: 64px 0;
  background: url(../../images/services-section-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #F8F4EE;

  .investment-form {
    max-width: 785px;
    margin: auto;

    .captcha-wrapper {
      max-width: 513px;

      .captcha-box {
        background: $white;
      }
    }

    .submit-btn {
      width: fit-content;
    }
  }

  .investment-background-wrapper {
    top: 165px;
    left: -195px;
    width: 500px;
    max-height: 650px;
  }
}

// Investment-section-end


// FAQ-section-start
.faq-section-wrapper {
  padding: 64px 0;
  background: #F8F4EE;

  .accordion-button {
    &:focus {
      box-shadow: none;
    }

    &:not(.collapsed) {
      background: transparent;
    }
  }

}

// FAQ-section-end


// Newsletter-section-start
.newsletter-section-wrapper {
  padding: 64px 0;
  overflow: hidden;

  .newsletter-heading {
    color: $white;
  }

  .newsletter-input-wrapper {
    max-width: 849px;
    margin: 60px auto 0;
    border: 1px solid $white;

    .newsletter-input {
      width: 100%;
      padding: 27px 33px;
      height: 78px;
      background-color: inherit;
      border: none !important;
      color: $white;

      &::placeholder {
        color: $white;
        font-weight: 400;
      }

      &:focus-visible {
        border: unset !important;
        outline: none;
      }
    }

    .subscribe-btn {
      padding: 0 33px;
      height: 78px;
      background-color: inherit;
      border-left: 1px solid $white;
      color: $white;
      opacity: 0.9;
    }
  }

  .social-links {
    margin-top: 60px;

    .text-divider {
      text-align: center;
      display: flex;
      align-items: center;

      .newsletter-link {
        text-transform: uppercase;
        padding: 0 24px;
        color: $white;
        font-weight: 500;
      }

      &::before,
      &::after {
        flex: 1;
        content: '';
        padding: 1px;
        background-color: $white;
        margin: 5px;
      }
    }
  }

  .newsletter-background-wrapper {
    right: -253px;
    top: -180px;
    height: 536px;
  }
}

// Newsletter-section-end

.homepage-footer {
  .footer-wrapper {
    background-color: #F8F4EE !important;
  }
}

/*--------------------------------------------------------------------------------- HOMEPAGE END ------------------------------------------------------------------------------------*/







/*------------------------------------------------------------------------------ GET STARTED PAGE START -----------------------------------------------------------------------------*/
.get-started-wrapper {

  .header {
    top: 34px;
    right: 68px;
    margin: auto;
    width: calc(100% - 136px);
    z-index: 1;
  }

  .started-wrapper {
    background: linear-gradient(to right, #E8F1F1, #F6F6F600);
    padding: 182px 0 52px;
  }

  .started-content {
    .started-form-wrapper {
      max-width: 515px;
      padding: 50px 34px;
      background: rgba(255, 255, 255, 0.7);
      margin: auto;

      .started-heading {
        width: calc(100% - 121px);
        font-size: 24px;
        font-weight: 600;
      }

      .started-form {
        .started-form-lable {
          font-weight: 500;
        }

        .started-form-input {
          border: 1px solid #E5E7EB;

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type=number] {
            -moz-appearance: textfield;
          }
        }

        .signup-divider {
          color: #9C9C9C;

          &::before,
          &::after {
            flex: 1;
            background-color: #9C9C9C;
            opacity: 0.4;
            content: '';
            padding: 1px;
            margin: 5px;
          }
        }
      }
    }

    .started-image-wrapper {
      max-width: 701px;
      max-height: 701px;

    }
  }

  .privacy-policy-wrapper {
    margin-top: 24px;

    .policies-content {
      margin-top: 30px;

      .policy-heading {
        font-size: 24px;
        color: #AC6231;
        margin-bottom: 16px;
      }

      .policy-contact-link {
        .policy-address {
          color: #3A3742;
          display: block;
        }

        .policy-contact-label {
          min-width: 80px;
          margin-right: 8px;
        }
      }
    }
  }

  .background-two {
    right: 0;
    top: 50%;
  }
}

/*------------------------------------------------------------------------------- GET STARTED PAGE END -------------------------------------------------------------------------------*/






/*--------------------------------------------------------------------------------- LOGIN PAGE START --------------------------------------------------------------------------------*/
.login-wrapper {
  background-image: url("../../../assets/images/loginPageBackground.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  background-size: cover;
  padding: 50px 12px;
  min-height: 100vh;


  .login-form-wrapper {
    max-width: 515px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    padding: 50px 32px;
    margin: auto;
    width: 100%;

    .login-logo-wrapper {
      width: 125px;
      height: 74px;
      margin: auto;

      .login-logo {
        .hotel-logo {
          width: 34px;
          height: 34px;
        }
      }
    }

    .login-heading {
      width: 100%;
      font-weight: 600;
    }

    .privacy-policy-text {
      text-decoration: underline;
      color: #1A3235B2;
    }

    .toggle-password {
      top: 14px;
      right: 18px;
      cursor: pointer;
    }
  }

}

/*---------------------------------------------------------------------------------- LOGIN PAGE END ----------------------------------------------------------------------------------*/





/*------------------------------------------------------------------------------ ONBOARDING PAGE START ------------------------------------------------------------------------------*/
.onboarding-wrapper {
  background: linear-gradient(#E8F1F1, #F6F6F600);

  .onboarding-header {
    background-color: white;
    box-shadow: 0px 1px 2px 0px #00000012;
    position: sticky;
    top: 0;
    z-index: 1;


    .onboarding-heading {
      padding-right: 32px;
      border-right: 1px solid #C4C4C4;
    }

    .onboarding-logo {
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .onboarding-button {
      flex-grow: 0.75;

      .onboarding-step-tag {
        .step-indicators {
          .step-indicator {
            .tag-name {
              font-weight: 600;
              color: #A2A5B9;

              &.active {
                color: #14413E;
              }
            }

            .step-number {
              height: 30px;
              width: 30px;
              font-weight: 700;
              color: white;
              background-color: #A2A5B9;

              &.active-background {
                background-color: #14413E;
              }
            }
          }
        }
      }
    }


  }

  .onboarding-mobile-header {
    display: none;
  }

  .onboarding-info-wrapper {
    .company-info-form {
      padding: 35px 32px;
      background: white;
      border: 1px solid #00000026;
    }

    .order-summary-wrapper {
      height: 943px;
      background-color: $white;
      border-radius: 10px;

      .order-summary-head-wrapper {
        width: 100%;
        height: 420px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-image: url("../../../assets/images/order-summary-background.png"), url("../../../assets/images/onboardingBackground2.png");
        background-blend-mode: multiply;
        background-position: unset, center;
      }

      .order-plans-wrapper {
        top: 172px;
        width: 100%;

        .plans-wrapper {
          max-width: 1439px;
          width: 90%;
          margin: 0 auto;
          justify-content: center;

          .subscription-card {
            padding: 50px 34px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px 0px #98959540;
            border-radius: 10px;
            height: 100%;

            .plan-toggle-wrapper {
              margin-top: 44px;
              border-radius: 35px;
              background: #DFE3EB;

              .plan-toggle-btn {
                padding: 18px 20px;
                border-radius: 35px;
                text-align: center;

                &.active {
                  border: 1px solid #0FA9C0;
                  background-color: white;
                }
              }
            }


            .icon-size {
              font-size: 18px;
            }

            .price-text {
              max-width: 160px;
            }

            .dot-circle {
              width: 4px;
              height: 4px;
              margin-top: 8px;
              border-radius: 100%;
              background-color: #3A3742;
            }

            .subscription-input {
              input {
                height: 32px;
                width: 138px;
                border: 1px solid #D9D9D9 !important;
                border-radius: 4px;
                padding-right: 0 !important;

                ::placeholder {
                  font-size: 14px;
                  color: #AAAAAA;
                }
              }
            }

            .subscription-card-contact {
              margin-top: 20px;

              .subs-card-mobile,
              .subs-card-email {
                .subs-mobile-icon {
                  background: #14413e1a;
                  padding: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 6px;
                  margin-right: 10px;

                  i {
                    font-size: 14px;
                  }
                }

                .subs-contact-info {
                  font-size: 14px;
                }

                .subs-card-info-link {
                  color: #0fa9c0;
                }
              }
            }
          }

        }
      }
    }
  }

  .onboarding-catory-wrapper {
    padding: 35px 32px;
    background: white;
    border: 1px solid #00000026;

    .onboarding-catory-selection-box {
      .onboarding-catory-selection {
        margin-bottom: 18px;
        margin-top: 15px;

        .onboarding-radio-label {
          font-weight: 600;
        }
      }
    }
  }

  .radio-info {
    padding: 35px 32px;
    background: white;
    border: 1px solid #00000026;

    .subdomain-wrapper {
      .subdomain-link-generator {
        width: 100%;
      }
    }

  }

  .started-form-lable {
    font-weight: 500;
    color: #3A3742;
  }
}

/*-------------------------------------------------------------------------------- ONBOARDING PAGE END -------------------------------------------------------------------------------*/





/*----------------------------------------------------------------------------- MANAGE SUBSCRIPTION PAGE START -----------------------------------------------------------------------*/
.manage-subscription-wrapper {
  background: linear-gradient(#E8F1F1, #F6F6F600);

  .header {
    .main-header {
      background-color: #FFFFFF99 !important;
    }
  }
}


/*------------------------------------------------------------------------------- MANAGE SUBSCRIPTION PAGE END -----------------------------------------------------------------------*/
.privacy-policy-section-wrapper {
  background-color: #E9EDED !important;

  .privacy-policy-section {
    max-width: 1333px;
    padding: 182px 0 52px;
  }

  .background-two {
    top: 424px;
  }

  .left-design {
    left: -525px;
    top: 663px;
  }
}

.confirmation-modal, .disable-modal {
  .mdc-dialog {
    .mat-mdc-dialog-surface {
      padding: 55px;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0 0 30px 0 #00000026;

      .mdc-dialog__content {
        padding: 0;

        .material-icons {
          font-size: 31px;
          color: #3EB049;
          margin-bottom: 15px;
        }

        .material-symbols-outlined {
          color: #F95050;
          font-size: 32px;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          color: #111429;
          line-height: 24px;
          margin: 0 0 28px;
          font-family: "Nunito Sans";
        }
      }

      .buttons-wrapper {
        gap: 0 10px;
        justify-content: center;
        padding: 0;
        min-height: unset;

        button {
          width: auto;
          font-size: 14px;
          margin: 0;
          padding: 0 14px;
          font-family: "Nunito Sans";
        }
      }
    }
  }
}

.subscription-invoice {
  .mdc-dialog {
    .mat-mdc-dialog-surface {
      text-align: center;
      box-shadow: 0 0 30px #00000026;

      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
  }
}

.mdc-dialog {
  .mat-mdc-dialog-surface {
    border-radius: 10px !important;
  }
}