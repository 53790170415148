// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

// Utilities
@import "mixins/breakpoints";
@import "mixins/hover";
@import "mixins/image";
@import "mixins/badge";
@import "mixins/resize";
@import "mixins/screen-reader";
@import "mixins/size";
@import "mixins/reset-text";
@import "mixins/text-emphasis";
@import "mixins/text-hide";
@import "mixins/text-truncate";
@import "mixins/visibility";

// // Components
@import "mixins/alert";
@import "mixins/buttons";
@import "mixins/caret";
@import "mixins/pagination";
@import "mixins/lists";
@import "mixins/list-group";
@import "mixins/nav-divider";
@import "mixins/forms";
@import "mixins/table-row";

// // Skins
@import "mixins/background-variant";
@import "mixins/border-radius";
@import "mixins/box-shadow";
@import "mixins/gradients";
@import "mixins/transition";

// // Layout
@import "mixins/clearfix";
@import "mixins/grid-framework";
@import "mixins/grid";
@import "mixins/float";


// Flexbox interpolation
// Usage // @include flex(flex);
@mixin flex($properties) {
    @if $properties == 'flex' {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex !important;
    } @else if $properties == 'inline-flex' {
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
    } @else if $properties == 'inline-block' {
        display: inline-block;
    } @else if $properties == 'block' {
        display: block;
	} @else if $properties == 'none' {
        display: none;
	} @else if $properties == 'list-item' {
        display: list-item;
    }
}

// Usage // @include flex-direction();
@mixin flex-direction($properties) {
    -webkit-flex-direction: $properties;
    -ms-flex-direction: $properties;
    flex-direction: $properties;
}

// Usage // @include flex-wrap(wrap);
@mixin flex-wrap($properties) {
    @if $properties == 'nowrap' {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    } @else if $properties == 'wrap' {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    } @else if $properties == 'wrap-reverse' {
        -webkit-flex-wrap: wrap-reverse;
        -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    }
}

// Usage // @include justify(center);
@mixin justify($properties) {
    @if $properties == 'flex-start' {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    } @else if $properties == 'flex-end' {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    } @else if $properties == 'center' {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    } @else if $properties == 'space-between' {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    } @else if $properties == 'space-around' {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
}

// Usage // @include align-items(center);
@mixin align-items($properties) {
    @if $properties == 'flex-start' {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    } @else if $properties == 'flex-end' {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    } @else if $properties == 'center' {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    } @else if $properties == 'baseline' {
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    } @else if $properties == 'stretch' {
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Box mixin

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}
// Usage @include box(200px, 300px);

// Font mixin 

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: $font-size * 1px;

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing / $font-size}em;
  }
}

// Usage @include font-size(12, 18, 1.2);

// Hide and show mixin 

@mixin fade($type) {
  @if $type== "hide" {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s;
  } @else if $type== "show" {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
  }
}

// Usage @include fade(hide); Or @include fade(show);

// Center verticle

@mixin vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

// Usage @include vertical-center();

// Opacity

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}

// Usage @include opacity(0.5);

// Position mixin

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}

// Usage @include absolute(top 20px right 20px bottom 20px left 10px);


// Gradient mixin

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

// Usage @include gradient(#07c, #06f, vertical);

// Border radius mixin

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius:$radius;
  -webkit-border-radius:$radius;
  -o-border-radius:$radius;
  -ms-border-radius:$radius;
}

// Usage @include border-radius(10px);

// Margin and padding mixin

@mixin margin-padding($m-direction, $m-amount, $p-direction, $p-amount) {
  @if $m-direction == all {
    margin: $m-amount;
  } @else {
    margin-#{$m-direction}: $m-amount;
  }
  @if $p-direction == all {
    padding: $p-amount;
  } @else {
    padding-#{$p-direction}: $p-amount;
  }
}

// Usage @include margin-padding(all, 0 auto 5%, null, null);

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 16) + px;
  font-size: $sizeValue + rem;
}

// Usage @include font-size(13);


@mixin custom-box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

// Usage @include box-shadow(5px, 5px, 5px, #333)