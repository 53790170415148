button {
    padding: 12px;
    width: 100%;
    &.mdc-button {
        font-family: "Nunito Sans";
        font-weight: 500;
    }
    &.mat-mdc-unelevated-button.mat-unthemed {
        --mdc-filled-button-container-color: #14413E;
        color: white;
        font-weight: 400;
        &.btn-gray {
            --mdc-filled-button-container-color: #F5F6F8;
            color: #59637C;
            &:not(:disabled) {
                background-color: #F5F6F8;
            }
        }
    }
    &.btn-rounded {
        &.mat-mdc-unelevated-button.mat-mdc-button-base {
            border-radius: 30px;
        }
    }
    &.mat-mdc-unelevated-button.mat-mdc-button-base {
        &.btn-md {
            height: 42px;
            font-size: 14px;
        }
        &.btn-sm {
            height: 34px;
            font-size: 14px;
        }
    }
    &.btn-primary {
        --mdc-filled-button-container-color: #14413E;
        background: #14413E;
        color: #fff;
        font-size: 14px;
        font-family: "Nunito Sans";
        border-radius: 6px;
        border: none;
        padding: 12px 20px;
        cursor: pointer;
        &.mat-mdc-unelevated-button:disabled {
            --mdc-filled-button-container-color: #14413E;
            background: #14413E;
            pointer-events: all;
            cursor: pointer;
        }
        &.mat-mdc-button:not(:disabled) {
            color: #fff;
        }
    }
    &.btn-outlined {
        border: 1px solid #14413E !important;
        --mdc-filled-button-container-color: transparent;
        color: #14413E !important;
        font-size: 14px;
        font-family: "Nunito Sans";
        border-radius: 6px;
        border: none;
        padding: 12px 20px;
        cursor: pointer;
        font-weight: 600 !important;
        &:not(:disabled) {
            --mdc-filled-button-container-color: transparent;
            color: #14413E !important;
        }
    }
}