.primary-color {
    color: #3A3742;
}

.text-black{
    color: #000000;
}

.bg-white{
    background-color: $white !important;
}

.heading-color {
    color: #14413E;
}

.onboarding-heading-color{
    color: #111429;
}

.subheading-color{
    color:#AC6231;
}

.primary-background-color {
    background-color: #14413E;
}

.bg-cream{
    background: #F8F4EE;
}

.footer-text-color{
    color: #333333;
}

.verification-color{
    color: #38AB7C;
}

.manage-profile-background{
    background: linear-gradient(#E8F1F1, rgba(246, 246, 246, 0));
}

.text-red{
    color:#E4004D;
}

.text-blue{
    color: #0FA9C0;
}

.testimonial-bg-color{
    background: #f7f1e6 !important;
}